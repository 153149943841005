<template>
  <div>
    <section>
      <GoogleIntegrationButton v-if="1 == 2" />
    </section>
    <!-- BIG NUMBERS -->
    <section class="mb-5">
      <div class="d-lg-flex justify-content-between align-items-center mb-5">
        <div>
          <h2 class="highlighted-color1 font-size-medium">
            <span>Resumo das suas campanhas ativas (últimos 30 dias)</span>
          </h2>
        </div>
        <div class="d-lg-flex text-center">
          <CreateCampaignButton
            text="Criar uma nova campanha"
          />
        </div>
      </div>
      <div v-if="summaryLoader" class="text-center">
        <Loader
          :size="'big'"
        />
      </div>
      <CampaignSummary
        :data="summary"
        v-else-if="summary"
      />

      <div class="d-lg-flex text-center justify-content-end">
        <RedirectToDashboardsButton/>
      </div>
    </section> <!-- END BIG NUMBERS -->
  </div>
</template>

<script>

import CampaignsService from '@/modules/campaigns/services/campaigns-service'
import moment from 'moment'

import CampaignSummary from '@/components/campaigns/CampaignSummary'
import CreateCampaignButton from '@/components/campaigns/CreateCampaignButton'
import RedirectToDashboardsButton from '@/components/dashboards/RedirectToDashboardsButton.vue'
// import GoogleIntegrationButton from '@/components/google/GoogleIntegrationButton'
// import DateTimeInput from '@/components/common/Input/DateTimeInput'
import Loader from '@/components/common/Loader/Loader'

export default {
  components: {
    CampaignSummary,
    CreateCampaignButton,
    RedirectToDashboardsButton,
    // GoogleIntegrationButton,
    // DateTimeInput,
    Loader
  },
  data () {
    return {
      filters: {
        summaryStart: '',
        summaryEnd: ''
      },
      summary: null,
      summaryLoader: true
    }
  },
  metaInfo () {
    return {
      title: 'Home'
    }
  },
  created () {
    this.filters.summaryStart = moment({ hour: 0, minute: 0, seconds: 0 }).subtract(30, 'days').format()
    this.filters.summaryEnd = moment({ hour: 0, minute: 0, seconds: 0 }).subtract(1, 'days').format()
    this.getCampaignSummary()
  },
  methods: {
    /**
     * Get campaign summary
     */
    async getCampaignSummary () {
      this.summaryLoader = true
      try {
        const summary = await CampaignsService.getCampaignsSummary({
          ids: [],
          startDate: moment(this.filters.summaryStart).format('YYYY-MM-DD'),
          endDate: moment(this.filters.summaryEnd).format('YYYY-MM-DD')
        })
        this.summary = summary
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
      }
      this.summaryLoader = false
    }
  }
}
</script>
